<template>
    <div class="root">
        <div class="root-top">
            <div class="root-top-left">
                <div class="root-top-left-left">
                    <div class="title">个人参与情况</div>
                    <div class="table">
                        <el-input
                            placeholder="请输入内容"
                            suffix-icon="el-icon-search"
                            v-model="input2">
                        </el-input>
                        <el-table
                        :highlight-current-row="true"
                        :stripe="false"
                        :show-header="false"
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                        align="center"
                            prop="date">
                        </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="root-top-left-middle">
                    <div class="id">
                        <div class="id-top">
                            <div></div>
                            <img src="../assets/logo.png">
                            <div class="text" style="margin-top: 1vh;">
                                <div style="font-size: 0.1rem;">中国人民政治协商会议</div>
                                <div style="font-size: 0.08rem;">第九届委员会</div>
                            </div>
                        </div>
                        <div class="id-bottom">
                            <span style="font-size: 0.08rem;">姓名：周正</span>
                            <span style="font-size: 0.08rem;">委制：委员/主席</span>
                            <span style="font-size: 0.08rem;">编号：Y-125</span>
                            <div class="id-bottom-img"><img src="../assets/zj_code.jpg"></div>
                            
                        </div>
                        <div class="peopleImg"><img src="../assets/zjz_cz.jpg"></div>
                        <div class="bot">此证只限证明委员身份</div>
                    </div>
                </div>
                <div class="root-top-left-right" id="main"></div>
            </div>
            <div class="root-top-right">
                <div class="title"  style="font-size: 0.1rem;">近期会议情况</div>
                <div class="chartsTwo" id="mianTwo"></div>
            </div>
        </div>
        <div class="root-bottom">
            <div class="title">会议/活动类型</div>
            <div class="ball"><span>提案</span><span>15%</span></div>
            <div class="ball"><span>社情民意</span><span>5%</span></div>
            <div class="ball"><span>会议</span><span>5%</span></div>
            <div class="ball"><span>培训</span><span>4%</span></div>
            <div class="ball"><span>其他</span><span>3%</span></div>
            <div class="ball"><span>公益活动</span><span>6%</span></div>
            <div class="ball"><span>视察调研</span><span>7%</span></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    name:'Meeting',
    data() {
        return {
            myChart:'',
            myCharsTwo:'',
            tableData:[{date:'程凡'},{date:'李芳'},{date:'刘伟'},{date:'周之祯'},{date:'王利华'},{date:'刘琳娜'},{date:'刘强国'},{date:'王立伟'},{date:'李军'},{date:'刘海'},{date:'唐祖'},{date:'廖明龙'},{date:'杨文文'},{date:'杨国'}]
        }
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.myChart.resize();
            this.myCharsTwo.resize();
        });
        this.myChars()
        this.myCharTwo()
    },
    methods: {
        myChars(){
        let chartDom = document.getElementById('main');
        this.myChart = echarts.init(chartDom);
        let option;
        option = {
            grid:{
                left:"3%",
                right:"3%"
            },
            radar: {
                // shape: 'circle',
                indicator: [
                { name: '提案', max: 6500 },
                { name: '视察调研', max: 16000 },
                { name: '有事好商量', max: 30000 },
                { name: '公益活动', max: 38000 },
                { name: '培训', max: 52000 },
                { name: '会议', max: 25000 }
                ]
            },
            series: [
                {
                name: 'Budget vs spending',
                type: 'radar',
                data: [
                    {
                    value: [4200, 3000, 20000, 35000, 50000, 18000],
                    name: 'Allocated Budget'
                    },
                    {
                    value: [5000, 14000, 28000, 26000, 42000, 21000],
                    name: 'Actual Spending'
                    }
                ]
                }
            ]
        };

        option && this.myChart.setOption(option);

        },
        myCharTwo(){
        let chartDom = document.getElementById('mianTwo');
        this.myCharsTwo = echarts.init(chartDom);
        let option;
        option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {
				textStyle:{
						color:"#fff"
					},
			},
            grid: {
                left: '1%',
                right: '7%',
                bottom: '3%',
                containLabel: true
            },
			color:['#7ac756','#e29836','#f26161'],
            xAxis: {
                type: 'value',
                axisLabel:{formatter:'{value} %'},
				axisLabel:{
					textStyle:{
							color:"#fff"
						},
				},
            },
            yAxis: {
                type: 'category',
                data: ['其他', '活动', '视察调研', '培训', '会议'],
				axisLabel:{
					textStyle:{
							color:"#fff"
						},
				},
            },
            series: [
                {
                name: '参加',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter:function(params){
                        return params.value+'%'
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: [51,39,50,51,38]
                },
                {
                name: '请假',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter:function(params){
                        return params.value+'%'
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: [39,54,44,36,57]
                },
                {
                name: '报名未参加',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter:function(params){
                        return params.value+'%'
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: [8,5,5,11,4]
                }
            ]
            };

            option && this.myCharsTwo.setOption(option);


        }
    },
    
}
</script>
<style lang="less" scoped>
.root{
    height: 83vh;
    width:90%;
    margin:auto;
    &-top{
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: space-between;
        &-left{
            width: 73%;
            height: 100%;
            border: 2px solid rgb(22,132,252);
            display: flex;
            &-left{
                width: 20%;
                height: 100%;
                padding: 1vh;
                .title{
                    width: fit-content;
                    font-size: 0.1rem;
                    color: rgb(11, 90, 245);
                    border: 1px solid rgb(11, 90, 245);
                    padding: 4px 0.2rem;
                    border-radius: 5px;
                    overflow: hidden;
                    
                }
                .table{
                    margin-top: 20%;
                    width: 100%;
                    height: 70%;
                    .el-table{
                        margin-top: 5px;
                        height: 85%;
                        overflow: auto;
                        &::-webkit-scrollbar{
                            background: white;
                            width: 2px;
                        }
                        &::-webkit-scrollbar-thumb{
                            background: rgb(179, 179, 179);
                        }
                    }
                }
            }
            &-middle{
                width: 35%;
                height: 100%;
                position: relative;
                .id{
                    color: rgb(246, 160, 0);

                    width: 100%;
                    height: 50%;
                    background-image: url('../assets/zj_bg.png');
                    background-size: 100% 100%;
                    position: absolute;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    overflow: hidden;
                    &-top{
                        display: flex;
                        align-items: center;
                        margin-left: 5%;
                        margin-top: 2%;
                        height: 18%;
                        width: 95%;

                        img{
                            width: 0.3rem;
                            height: 0.3rem;
                        }
                        .text{
                            margin-left: 2%;
                            height: 0.3rem;
                            div:nth-child(1){
                                font-size: 0.14rem;
                            }
                            div:nth-child(2){
                                font-size: 0.1rem;
                            }
                        }
                    }
                    &-bottom{
                        height: 78%;
                        width: 80%;
                        overflow: hidden;
                        margin-top: 2%;
                        margin-left: 10%;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        font-size: 0.10rem;
                        padding-bottom: 5%;
                        span{
                            margin-bottom: 2%;
                        }
                        &-img{
                            width: .5rem;
                            height: .5rem;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .peopleImg{
                        position: absolute;
                        right: 5%;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 25%;
                        height: 50%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .bot{
                        font-size: 0.08rem;
                        position: absolute;
                        bottom:2px;
                        right: 10%;
                        transform: scale(0.8);
                    }
                }
            }
            &-right{
                width: 45%;
                height: 100%;
            }
        }
        &-right{
            width: 26%;
            height: 100%;
            border: 1px solid rgb(217, 217, 217);
            padding: 1vh;
			border-radius: 5px;
			box-shadow: 0 0 5px #c0c0c0;
            .title{
                    width: fit-content;
                    font-size: 0.12rem;
                    color: rgb(11, 90, 245);
                    border: 1px solid rgb(11, 90, 245);
                    padding: 4px 0.2rem;
                    border-radius: 5px;
                    overflow: hidden;
            }
            .chartsTwo{
                width: 100%;
                height: 90%;
                margin-top: 3%;
            }
        }
    }
    &-bottom{
        width: 100%;
        height: 34%;
        margin-top: 2%;
        border: 1px solid rgb(217, 217, 217);
        position: relative;
        padding: 1vh;
		border-radius: 5px;
		box-shadow: 0 0 5px #c0c0c0;
        .title{
                    width: fit-content;
                    font-size: 0.1rem;
                    color: rgb(11, 90, 245);
                    border: 1px solid rgb(11, 90, 245);
                    padding: 4px 0.2rem;
                    border-radius: 5px;
                    overflow: hidden;
            }
        .ball{
            color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span:nth-child(2){
                font-size: 0.1rem;
            }
        }
		div{
			font-size: 0.1rem;
		}
        div:nth-child(2){
                width: 1rem;
                height: 1rem;
                background-color: rgb(86, 115, 243);
                position: absolute;
                top: 27%;
                left: 5%;
        }
        div:nth-child(3){
                width: 0.8rem;
                height: 0.8rem;
                background-color: rgb(192, 192, 0);
                position: absolute;
                top: 10%;
                left: 18%;
        }
        div:nth-child(4){
                width: 1rem;
                height: 1rem;
                background-color: rgb(219, 95, 147);
                position: absolute;
                top: 25%;
                left: 28%;
        }
        div:nth-child(5){
                width: .6rem;
                height: .6rem;
                background-color: rgb(47, 194, 67);
                position: absolute;
                top: 20%;
                left: 44%;
        }
        div:nth-child(6){
                width: .8rem;
                height: .8rem;
                background-color: rgb(42, 189, 152);
                position: absolute;
                top: 40%;
                left: 58%;
        }
        div:nth-child(7){
                width: 1.1rem;
                height: 1.1rem;
                background-color: rgb(104, 35, 117);
                position: absolute;
                top: 10%;
                left: 70%;
        }
        div:nth-child(8){
                width: 0.9rem;
                height: 0.9rem;
                background-color: rgb(180, 116, 42);
                position: absolute;
                top: 40%;
                left: 85%;
        }

    }
}

/deep/input{
			background: none !important;
		}
/deep/ .el-table tr{
	background-color: transparent;
	color: #fff;
}
/deep/ .el-table tr:hover{
	background-color: transparent;
	color: #000;
}
/deep/  .el-table, .el-table__expanded-cell {
        background-color: transparent;
    }
/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
	background-color: transparent;
}
/deep/.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
	border: 1px solid #fff !important;
}
/deep/ .el-table .cell{
	line-height: normal;
}
</style>